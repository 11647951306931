import axios from "axios";

const API_URL = 'https://gestione.valpesce.it/api/'

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "auth/username/login", {
                username,
                password
            })
            .then(response => {
                if (response.data?.data?.token) {
                    localStorage.setItem("user", JSON.stringify(response.data?.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }


    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();
