// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  box-shadow: 0px 4px 20px rgba(126, 130, 153, 0.15);
  border-radius: 5px;
  width: 90%;
  margin: 2rem auto;
  padding: 2.2rem 2rem; }
  .container .color {
    color: #80B8E5; }

.sailing-container {
  background: #E6F3FF;
  opacity: 0.8;
  border-radius: 10px;
  padding: 7rem 0;
  text-align: center; }

.sailing-container2 {
  background: #E6F3FF;
  opacity: 0.8;
  border-radius: 10px;
  padding: 2rem 2rem; }

.text-gray {
  color: #2E2E2E;
  font-size: 15px;
  font-weight: bold; }

.text-gray2 {
  color: #2E2E2E;
  font-size: 15px; }
`, "",{"version":3,"sources":["webpack://./src/pages/Imbarcazioni/imbarcazionDetailgo/imbarcazioni.scss"],"names":[],"mappings":"AAAA;EACI,kDAAkD;EAClD,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,oBAAoB,EAAA;EALxB;IAQQ,cAAc,EAAA;;AAItB;EACI,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB,EAAA;;AAGtB;EACI,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB,EAAA;;AAGtB;EACI,cAAc;EACd,eAAe;EACf,iBAAiB,EAAA;;AAGrB;EACI,cAAc;EACd,eAAe,EAAA","sourcesContent":[".container {\n    box-shadow: 0px 4px 20px rgba(126, 130, 153, 0.15);\n    border-radius: 5px;\n    width: 90%;\n    margin: 2rem auto;\n    padding: 2.2rem 2rem;\n\n    .color {\n        color: #80B8E5;\n    }\n}\n\n.sailing-container {\n    background: #E6F3FF;\n    opacity: 0.8;\n    border-radius: 10px;\n    padding: 7rem 0;\n    text-align: center;\n}\n\n.sailing-container2 {\n    background: #E6F3FF;\n    opacity: 0.8;\n    border-radius: 10px;\n    padding: 2rem 2rem;\n}\n\n.text-gray{\n    color: #2E2E2E;\n    font-size: 15px;\n    font-weight: bold;\n}\n\n.text-gray2{\n    color: #2E2E2E;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
