import React from 'react';
import {Backdrop, Button, CircularProgress, Menu, MenuItem} from "@mui/material";
import {IconUser} from "../icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ConfirmLogout from "../dialogs/confirm-logout";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/auth-service";

const MenuUser = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = ()=>{
        setOpenBackdrop(true);
        setOpenModal(false);
        AuthService.logout();
        setTimeout(function () {
            navigate('/login');
        },3000);
    }

    return (
        <div>
            <ConfirmLogout handleClose={()=>{setOpenModal(false)}}  open={openModal} actionTrue={logout}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: 'white',
                    textTransform: 'Capitalize'
                }}
                startIcon={<IconUser />}
                endIcon={<KeyboardArrowDownIcon sx={{ marginLeft: '-10px' }} />}
            >
                {AuthService.getCurrentUser()?.user.username}
            </Button>
            <Menu
                sx={{
                    '& .MuiMenu-list': {
                        backgroundColor: '#E6F3FF',
                        color: '#0070CB',
                        fontWeight: 'bold'
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {/* <MenuItem onClick={handleClose}>profilo</MenuItem> */}
                <MenuItem onClick={()=>{setOpenModal(true); handleClose();}}>logout</MenuItem>
            </Menu>
        </div>
    );
};

export default MenuUser;
