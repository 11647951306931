import axios from "axios";
import authHeader from "../common/authHeader";

const API_URL = 'https://gestione.valpesce.it/api/boat/'
class BoatService {
    list() {
        return axios
            .get(API_URL + "all" , {
                headers : {
                    ...authHeader()
                }
            });
    }

    getById(id) {
        return axios
            .get(API_URL + "get-by-id/"+id , {
                headers : {
                    ...authHeader()
                }
            });
    }


    register(data){
        return axios
            .post(API_URL + "create", {
                "userId": data.user,
                "name": data.name,
                "matrix": data.matrix,
                "nameplate": data.nameplate,
                "nu": Boolean(data.nu),
                "country": data.country,
                "data": data.data
            },{
                headers : {
                    ...authHeader()
                }
            });
    }

    update(id , data){
        return axios
            .put(API_URL + id, {
                "userId": data.user,
                "name": data.name,
                "matrix": data.matrix,
                "nu": Boolean(data.nu),
                "nameplate": data.nameplate,
                "country": data.country,
                "data": data.data
            },{
                headers : {
                    ...authHeader()
                }
            });
    }


    deleteById(id) {
        return axios
            .delete(API_URL +id , {
                headers : {
                    ...authHeader()
                }
            });
    }

}

export default new BoatService();
