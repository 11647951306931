import React, {lazy, Suspense, useEffect} from 'react';
import { ProtectedRoute } from "./common/protectedRoute";
import {Navigate, Route, Routes} from "react-router-dom";
import LayOut from "./common/LayOut";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles/App.scss'
import './../src/styles/app.css'
import ImbarcazioniForm from './pages/reportForms/imbarcazioniForm/imbarcazioniForm';
import ImbarcazionDetailgo from "./pages/Imbarcazioni/imbarcazionDetailgo";
import DettaglioAlberatura from "./pages/Imbarcazioni/dettaglioAlberatura";
import PageLoader from './components/pageLoader';
import ProcessiModificaForm from './pages/reportForms/processiModificaForm/processiModificaForm';
import EventBus from "./common/eventBus";
import {Backdrop, CircularProgress} from "@mui/material";
import EditImbarcazioniForm from "./pages/reportForms/imbarcazioniForm/imbarcazioniForm-edit";

const LoginPage = lazy(() => import('./pages/login/index'));
const RegisterPage = lazy(() => import('./pages/register/index'));
const NotFoundPage = lazy(() => import('./pages/notFound/index'));
const DashboardPage = lazy(() => import('./pages/dashboard/index'));
const ImbarcazioniPage = lazy(() => import('./pages/Imbarcazioni/index'));
const DetailFish = lazy(() => import('./pages/processi/detailFish/index'));
const AttivitaPage = lazy(() => import('./pages/attivita/index'));
const ProcessiPage = lazy(() => import('./pages/processi/index'));
const ProcessiQrPage = lazy(() => import('./pages/processi/dettaglioAlberaturaProcessi/index'));

export default function App() {

    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    useEffect(() => {
        EventBus.on("load", (value) => {
            setOpenBackdrop(value);
        });

        return () => {
            // remove event
            EventBus.remove("load");
        }
    }, []);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
                <Suspense fallback={<PageLoader />}>
                    <Routes>
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/' element={
                            <ProtectedRoute>
                                <LayOut />
                            </ProtectedRoute>
                        }>
                            <Route path="/" index element={<Navigate to={'/home'} replace />} />
                            <Route path="/home" index element={<DashboardPage />} />
                            <Route path='/attivita' element={<AttivitaPage />} />
                            <Route path='/processi' element={<ProcessiPage />} />
                            <Route path='/processi/:id' element={<ProcessiPage />} />
                            <Route path='/edit-processi/:id' element={<ProcessiModificaForm />} />
                            <Route path='/imbarcazioni' element={<ImbarcazioniPage />} />
                            <Route path='/add-imbarcazioniForm' element={<ImbarcazioniForm />} />
                            <Route path='/edit-imbarcazioni/:id' element={<EditImbarcazioniForm />} />
                            <Route path='/processis-details/:id' element={<DetailFish />} />
                            <Route path='/processi-previsualize/:id' element={<ProcessiQrPage />} />
                            <Route path='/imbacazionic-details/:id' element={<ImbarcazionDetailgo />} />
                            <Route path='/imbarcazione-previsualize/:id' element={<DettaglioAlberatura />} />
                            <Route path='*' element={<NotFoundPage />} />
                        </Route>
                        <Route path='/register' element={<RegisterPage />} />
                    </Routes>
                </Suspense>
        </>
    );
}
