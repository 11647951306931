import {Box, Breadcrumbs, Button, Grid, Skeleton, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import './imbarcazioni.scss'
import sailing from '../../../assets/svgs/sailing.svg'
import qr_code from '../../../assets/svgs/qr_code.svg'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import HistoryIcon from '@mui/icons-material/History';
import {IconEdit} from "../../../components/icons";
import imbarcazioniService from "../../../services/boat-service";
import AppDivider from "../../components/others/appDivider";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import {faker} from "@faker-js/faker";
const ImbarcazionDetailgo = () => {
    const navigation = useNavigate();
    const [imbarcazionDetailgo, setImbarcazionDetailgo] = useState(null);
    const [lastCapture, setLastCapture] = useState(null);
    const {id} = useParams();
    const temp = faker.datatype.number({min : -20 , max : 0});


    useEffect(() => {
        imbarcazioniService.getById(id)
            .then(function ({data}) {
                setImbarcazionDetailgo(data.data);
                setLastCapture(data.data.fishes.at(-1));
            })
            .catch(function () {

            })
            .finally(function () {

            })
    }, []);

    const WithLoad = ({data, component, w = 0, h = 0}) => {
        return (
            <>
                {
                    !data ?
                        <Skeleton variant="rounded" width={w} height={h} sx={{marginBottom: "10%"}}/> :
                        component}

            </>
        )
    }


    const Header = () => {
        return (
            <>
                <Stack padding={{
                    xs: '6%',
                    lg: "2% 6%"
                }} direction={{lg: 'row', xs: "column"}} justifyContent="space-between" spacing={2}>
                    <Stack spacing={2}>
                        <Typography variant="title_page" lineHeight="32px" color="#8093A6" fontSize="2rem"
                                    fontFamily="Roboto"
                                    fontWeight="bold">
                            Dettagli imbarcazione
                        </Typography>
                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                            <Link to="/Imbarcazioni" style={{textDecoration: 'none', color: '#8093A6'}}>
                                Imbarcazioni
                            </Link>
                            <Link
                                to="#"
                                style={{textDecoration: 'none', color: '#4D9BDB'}}
                            >
                                {imbarcazionDetailgo ? imbarcazionDetailgo?.name : ''}
                            </Link>
                        </Breadcrumbs>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <Button
                            startIcon={<HistoryIcon/>}
                            variant="outlined" size="large"
                            sx={{width: '150px', height: '50px', textTransform: "capitalize"}}
                            onClick={() => {
                                navigation('/processi/'+imbarcazionDetailgo?.id)
                            }}>
                            Storico
                        </Button>
                        <Button
                            startIcon={<IconEdit color={"#fff"}/>}
                            onClick={() => {
                                navigation('/edit-imbarcazioni/' + imbarcazionDetailgo?.id)
                            }}
                            variant="contained" size="large"
                            sx={{width: '150px', height: '50px', textTransform: "capitalize"}}>
                            Modifica
                        </Button>
                    </Stack>
                </Stack>
            </>
        )
    }

    const DeleteIcon = () => {
        return <img src={qr_code} alt="qr_code"/>
    }

    return (
        <>
            <Header/>
            <Box sx={{
                backgroundColor: '#fff',
                width: '88%',
                margin: '0 auto',
                marginBottom: 3,
                padding: {
                    lg: 5,
                    xs: 3
                },
                borderRadius: '5px',
                boxShadow: "0px 4px 20px rgba(126, 130, 153, 0.15)"
            }}>
                <Grid container spacing={{xs: 2, md: 8}} paddingBottom={10}>
                    <Grid item xs={12} md={4}>
                        <Box component='div' className='sailing-container'>
                            <Box>
                                <img src={sailing} alt="sailing"/>
                            </Box>
                        </Box>

                        <Box sx={{textAlign: 'center'}} mt='1.5rem' mb='0.5rem'>
                            <Button
                                sx={{textTransform: 'capitalize', padding: "3% 7%"}}
                                variant="outlined"
                                startIcon={<DeleteIcon/>}
                                onClick={() => {
                                    navigation('/imbarcazione-previsualize/' + imbarcazionDetailgo.id)
                                }}>
                                Visualizza i dati
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{paddingTop: '1rem'}}>
                            <WithLoad
                                data={imbarcazionDetailgo}
                                component={
                                    <Typography variant='h6' component='h3' color='primary.main' sx={{mb: '1.7rem'}}>
                                        {imbarcazionDetailgo?.name}
                                    </Typography>}
                                h={30} w={210}/>
                        </Box>
                        <Stack sx={{margin: '1rem 0'}} direction={{md: 'row'}} justifyContent='space-between'>
                            <Box mb={{xs: '0.5rem'}} flex='1'>
                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                Comandante
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.captain?.detail.first_name} {imbarcazionDetailgo?.captain?.detail.last_name}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>

                            <Box flex='1'>
                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                Bandiera /Nazionalità
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.country}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>
                        </Stack>

                        <Stack sx={{margin: '1rem 0'}} direction={{md: 'row'}} justifyContent='space-between'>
                            <Box mb={{xs: '0.5rem'}} flex='1'>
                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                N.UE
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.created_at ? 'Si' : 'no'}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>

                            <Box flex='1'>
                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                Matricola
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.matrix}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>
                        </Stack>

                        <Stack my='1.5rem' direction={{md: 'row'}} justifyContent='space-between'>
                            <Box mb={{xs: '0.5rem'}} flex='1'>
                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                Pubblico
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.data ? 'Si' : 'No'}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>
                            <Box flex='1'>

                                <WithLoad
                                    data={imbarcazionDetailgo}
                                    component={
                                        <>
                                            <Typography component='h5' className='text_primary'>
                                                Targa
                                            </Typography>
                                            <Typography component='div'>
                                                {imbarcazionDetailgo?.nameplate}
                                            </Typography>
                                        </>
                                    }
                                    h={30} w={210}/>
                            </Box>
                        </Stack>

                        <AppDivider/>

                        <Box component='div' className='sailing-container2'>
                            <Stack>
                                <Typography sx={{color: 'primary.main'}} fontWeight={'bold'} marginBottom={3}>
                                    Dati in tempo reale
                                </Typography>
                            </Stack>
                            <Stack marginBottom={3}>
                                <Typography>
                                    <FmdGoodOutlinedIcon color={"primary"} sx={{ marginRight : '15px' , marginBottom : '-10px'}}/>
                                    <span className={'text-gray'}>
                                        Ultimo segnale GPS rilevato
                                    </span>
                                </Typography>
                                <Stack direction={'row'} spacing={5} marginLeft={5}>
                                    <span className={'text-gray2'} >{lastCapture?.latitude ?? 0} N</span>
                                    <span className={'text-gray2'}>{lastCapture?.longitude ?? 0} E</span>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography>
                                    <KitchenOutlinedIcon color={"primary"} sx={{ marginRight : '15px' , marginBottom : '-10px'}}/>
                                    <span className={'text-gray'}>Ultima temperatura frigo rilevata</span>
                                </Typography>
                                <Stack direction={'row'} spacing={5} marginLeft={5}>
                                    <span className={'text-gray2'}>{temp}°</span>
                                </Stack>
                            </Stack>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ImbarcazionDetailgo;