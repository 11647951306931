import {
    Box,
    Button, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select,
    Stack, Switch,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import * as Yup from "yup";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from "react-router-dom";
import AppDivider from "../../components/others/appDivider";
import SuccessDialog from "../../components/dialog/successDialog";
import {useFormik} from "formik";
import UserService from "../../../services/users-service";
import BoatService from "../../../services/boat-service";
import EventBus from "../../../common/eventBus";

export const Subtitle = ({ title, styles }) => {
    return (
        <Box sx={{ "textAlign": "center", color: "primary.main", fontSize: 20, my: 4, fontWeight: "bold", ...styles }}>{title}</Box>
    )
}


const ImbarcazioniForm = () => {

    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const navigation = useNavigate();


    const Header = () => {
        return (
            <>
                <Stack padding={"2% 6%"} spacing={2} direction="row" justifyContent="space-between"
                >
                    <Box>
                        <Typography
                            variant="title_page"
                            color="#8093A6"
                            fontSize={{
                                lg: "2rem",
                                xs: '1.5rem'
                            }}
                            fontFamily="Roboto"
                            fontWeight="bold">
                            Aggiungi
                        </Typography>
                        <Link to="/Imbarcazioni" style={{ textDecoration: 'none', color: 'gray' }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <ArrowBackIcon sx={{ color: 'black' }} />
                                <span style={{ fontSize: '1.3rem', color: '#8093A6' }}>Imbarcazioni</span>
                            </Stack>
                        </Link>
                    </Box>

                    <Box>
                        <Button variant="outlined"
                                size="large"
                                sx={{ width: '100px', height: '50px', mx: 2, textTransform: "capitalize" }}
                                onClick={() => {
                                    navigation(-1)
                                }}>
                            Annulla
                        </Button>
                        <Button variant="contained"
                                size="large"
                                disabled={!boat.isValid}
                                onClick={boat.handleSubmit}
                                sx={{ width: '150px', height: '50px', mx: 2, textTransform: "capitalize" }}>
                            Salva
                        </Button>
                    </Box>
                </Stack>
            </>
        )
    }



    function getUsers() {
        UserService.getAllCaptain()
            .then(function ({data}) {
                setUsers(data.data.items)
            })
            .catch(function () {

            });
    }



    useEffect(() => {
       getUsers();
    }, []);


    const handleSubmit = function (d) {
        EventBus.dispatch('load', true);
        BoatService.register(d)
            .then(function ({data}) {
                setTimeout(function () {
                    EventBus.dispatch('load',false);
                    setShow(true);
                },3000)
            })
            .catch(function () {
                
            })
    }

    const boatSchema = Yup.object().shape({
        name: Yup.string()
            .required(),
        nameplate: Yup.string()
            .required(),
        matrix: Yup.string()
            .required(),
        country: Yup.string()
            .required(),
        nu: Yup.boolean()
            .required(),
        data: Yup.boolean(),
        user : Yup.number()
            .min(0)
            .required()
    });

    const boat = useFormik({
        initialValues: {
            name: '',
            user: 0,
            nu: false,
            matrix: '',
            country: '',
            nameplate: '',
            data: true,
        },
        onSubmit: handleSubmit,
        validationSchema : boatSchema
    });

    return (
        <Box>
            <SuccessDialog
                open={show}
                handleAction1={()=>{navigation('/Imbarcazioni')}}
                handleAction2={()=>{
                    navigation('/Imbarcazioni')
                }}
                handleClose={() => setShow(false)}
                handleShow={() => setShow(true)}
                modalTitle="Elemento salvato con successo!"
                size="xs"
            >
                <Typography variant={'body1'}>
                    ID transazione  2039482039482084209348023492038420
                </Typography>
            </SuccessDialog>
            <Header show={show} setShow={setShow} navigation={navigation} />
            <Box
                sx={{
                    width: "60%", mx: 'auto', backgroundColor: "#fff", py: 5, px: "5%", boxShadow: "0px 4px 20px rgba(126, 130, 153, 0.15)", mb: 5
                }}
            >
                <Subtitle title="Aggiungi imbarcazione" />
                <AppDivider />
                <form onSubmit={boat.handleSubmit}>
                    <Stack spacing={4} paddingBottom={10}>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                Nome imbarcazione*
                            </Typography>
                            <OutlinedInput
                                name="name"
                                sx={{ backgroundColor : '#F4F6FB'}}
                                onChange={boat.handleChange}
                                value={boat.values.name}
                                placeholder="Inserisci il nome imbarcazione" />
                            {/*<AppFormHelperText text={boat.errors.name}/>*/}
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                Comandante*
                            </Typography>
                            <Select
                                name="user"
                                onChange={boat.handleChange}
                                value={boat.values.user}
                                sx={{ backgroundColor : '#F4F6FB'}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <span style={{color : 'gray'}}>Seleziona il comandante</span>
                                </MenuItem>
                                {users.map((el)=>{
                                    return (
                                            <MenuItem disabled={el?.boats} key={el.id} value={el.id}>{el?.detail?.first_name} {el?.detail?.last_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                Bandiera / nazionalità*
                            </Typography>
                            <OutlinedInput
                                sx={{ backgroundColor : '#F4F6FB'}}
                                name="country"
                                onChange={boat.handleChange}
                                value={boat.values.country}
                                placeholder="Inserisci bandiera / nazionalità" />
                            {/*<AppFormHelperText/>*/}
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                N.UE*
                            </Typography>
                            <RadioGroup
                                aria-labelledby="nu"
                                defaultValue={true}
                                name="nu"
                                value={boat.values.nu}
                                onChange={boat.handleChange}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Sì" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                            {/*<AppFormHelperText/>*/}
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                Matricola*
                            </Typography>
                            <OutlinedInput
                                name="matrix"
                                onChange={boat.handleChange}
                                value={boat.values.matrix}
                                sx={{ backgroundColor : '#F4F6FB'}}
                                placeholder="Inserisci matricola" />
                            {/*<AppFormHelperText/>*/}
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant={"body1"}
                                        fontWeight={'bold'}
                                        sx={{color : '#4D9BDB'}}>
                                Targa*
                            </Typography>
                            <OutlinedInput
                                name="nameplate"
                                onChange={boat.handleChange}
                                value={boat.values.nameplate}
                                sx={{ backgroundColor : '#F4F6FB'}}
                                placeholder="Inserisci targa" />
                            {/*<AppFormHelperText/>*/}
                        </Stack>
                        <Stack>
                            <Stack spacing={2} justifyContent={"center"} alignItems={"center"} direction={"row"}>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color : '#4D9BDB'}}>
                                    Dati imbarcazione
                                </Typography>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: boat.values.data ? 'lightgray' : '#0070CB'}}
                                            >
                                    Privato
                                </Typography>
                                <FormControlLabel
                                    name="data"
                                    onChange={boat.handleChange}
                                    value={boat.values.data}
                                    control={<Switch defaultChecked />}/>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: !boat.values.data ? 'lightgray' : '#0070CB'}}>
                                    Pubblico
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={2} justifyContent={"center"} alignItems={"center"} direction={"row"}>
                            <Button variant="outlined" size="large"
                                    sx={{ width: '100px', height: '50px', mx: 2, textTransform: "capitalize" }}
                                    onClick={() => {
                                        navigation(-1)
                                    }}>
                                Annulla
                            </Button>
                            <Button variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={!boat.isValid}
                                    sx={{ width: '150px', height: '50px', mx: 2, textTransform: "capitalize" }}>
                                Salva
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Box >
    )
}

export default ImbarcazioniForm


