import React from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import {IconSetting} from "../icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuSetting = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            <Button
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: 'white',
                }}
                startIcon={<IconSetting />}
                endIcon={<KeyboardArrowDownIcon sx={{ marginLeft: '-10px' }} />}>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-list': {
                        backgroundColor: '#E6F3FF',
                        color: '#0070CB'
                    }
                }}
            >
                <MenuItem onClick={handleClose}>unità di misura</MenuItem>
                <MenuItem onClick={handleClose}>tabella di conversione</MenuItem>
                <MenuItem onClick={handleClose}>manuale utente</MenuItem>
            </Menu>
        </div>
    );
};

export default MenuSetting;
