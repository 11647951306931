import axios from "axios";
import authHeader from "../common/authHeader";

const API_URL = 'https://gestione.valpesce.it/api/users/'

class UserService {
    getAllCaptain(){
        return axios
            .get(API_URL + "all?page=1&role=CAPTAIN&limit=90" , {
                headers : {
                    ...authHeader()
                }
            });
    }


}

export default new UserService();
