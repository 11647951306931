import axios from "axios";
import authHeader from "../common/authHeader";

const API_URL = 'https://gestione.valpesce.it/api/fish/'

class FishService {
    listPending(id = -1) {
        if(id < 0){
            return axios
                .get(API_URL + "all?status=PENDING" , {
                    headers : {
                        ...authHeader()
                    }
                });
        }else {
            return axios
                .get(API_URL + "all?status=PENDING&boat_id="+id , {
                    headers : {
                        ...authHeader()
                    }
                });
        }
    }

    listComplete(id = -1) {
        if(id < 0){
            return axios
                .get(API_URL + "all?status=COMPLETE" , {
                    headers : {
                        ...authHeader()
                    }
                });
        }else {
            return axios
                .get(API_URL + "all?status=COMPLETE&boat_id="+id , {
                    headers : {
                        ...authHeader()
                    }
                });
        }

    }


    update(id ,data){
        return axios
            .put(API_URL + id, {
                "name_commercial": data.commercial,
                "quantity": data.quantity,
                "units": data.units,
                "status": "COMPLETE",
                "fish_species": data.specie,
                "area": "37",
                "products": data.products,
            },{
                headers : {
                    ...authHeader()
                }
            });
    }

    getById(id) {
        return axios
            .get(API_URL +id , {
                headers: {
                    ...authHeader()
                }
            });
    }



    deleteById(id) {
        return axios
            .delete(API_URL +id , {
                headers: {
                    ...authHeader()
                }
            });
    }

}

export default new FishService();
