import { Box } from '@mui/material'
import React from 'react'

const AppDivider = () => {
    return (
        <Box sx={{ borderBottom: "1px dashed #E6F3FF", my: 5 }}></Box>
    )
}

export default AppDivider
