import * as React from 'react';
import {createRoot} from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import App from './App';
import {ErrorBoundary} from "react-error-boundary";
import {BrowserRouter} from "react-router-dom";
import theme from "./theme";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

root.render(
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            // reset the state of your app so the error doesn't happen again
        }}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <App/>
            </ThemeProvider>
        </BrowserRouter>
    </ErrorBoundary>
);
