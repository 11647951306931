import axios from "axios";
import authHeader from "../common/authHeader";

const API_URL = 'https://gestione.valpesce.it/api/v1/blockchain/'
class BlockchainService {
    
    getTxReceipt(id) {
        return axios
            .get(API_URL + "get_tx_receipt/"+id , {
                headers : {
                    ...authHeader()
                }
            });
    }

}

export default new BlockchainService();
