// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  box-shadow: 0px 4px 20px rgba(126, 130, 153, 0.15);
  border-radius: 5px;
  width: 90%;
  margin: 2rem auto;
  padding: 3rem 2rem;
  position: relative; }

.qr-code-box2 {
  position: absolute;
  top: 2rem;
  right: 5rem;
  padding: 5rem 3rem;
  background: #E6F3FF;
  border-radius: 10px;
  color: #b2bbc4; }

.qr-code-box {
  width: 200px;
  height: 200px; }

@media print {
  @page {
    size: A4 portrait;
    padding: 10mm; }
  .print-style {
    padding: 3rem;
    width: 50%; }
  .qr-code-box {
    padding: 0;
    width: 150px;
    height: 150px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/Imbarcazioni/dettaglioAlberatura/imbarcozioni2.scss"],"names":[],"mappings":"AAAA;EACI,kDAAkD;EAClD,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB,EAAA;;AAGtB;EACI,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,cAAc,EAAA;;AAGlB;EACI,YAAa;EACb,aAAc,EAAA;;AAGlB;EACI;IACI,iBAAiB;IACjB,aAAa,EAAA;EAEjB;IACI,aAAa;IACb,UAAU,EAAA;EAGd;IACI,UAAU;IACV,YAAY;IACZ,aAAa,EAAA,EAEhB","sourcesContent":[".container {\n    box-shadow: 0px 4px 20px rgba(126, 130, 153, 0.15);\n    border-radius: 5px;\n    width: 90%;\n    margin: 2rem auto;\n    padding: 3rem 2rem;\n    position: relative;\n}\n\n.qr-code-box2{\n    position: absolute;\n    top: 2rem;\n    right: 5rem;\n    padding: 5rem 3rem;\n    background: #E6F3FF;\n    border-radius: 10px;\n    color: #b2bbc4;\n}\n\n.qr-code-box{\n    width : 200px;\n    height : 200px;\n}\n\n@media print {\n    @page  {\n        size: A4 portrait;\n        padding: 10mm;\n    }\n    .print-style{\n        padding: 3rem;\n        width: 50%;\n    }\n\n    .qr-code-box{\n        padding: 0;\n        width: 150px;\n        height: 150px;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
