import * as React from 'react';
import { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Drawer, Hidden, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import logo from './../assets/images/logos/logo01.png';
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import { NavBar } from "../components/NavBar";
import ScrollTop from '../components/scrollTotop/ScrollTop';

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('xs')]: {
            width: 'calc(100%)',
        },
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const DrawerCustom = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DashboardContent() {
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.up('sm'));
    const mobile = useMediaQuery(theme.breakpoints.up('xs'));
    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [openMobile, setOpenMobile] = useState(false);
    const toggleDrawerMobile =
        (anchor, open) =>
            (event) => {
                if (event.type === 'keydown' && event.key === 'Tab' || event.key === 'Shift') {
                    return;
                }

                setOpenMobile(open)
            };


    const MenuMobile = () => {
        return (
            <>
                <Drawer
                    anchor={"left"}
                    open={openMobile}
                    sx={{
                        zIndex: '2000'
                    }}
                    onClose={toggleDrawerMobile("left", false)}
                >
                    <Box sx={{ width: '70vw', backgroundColor: '#fff', height: '75%' }}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                padding: '10% 0 18% 0',
                                px: [1],
                            }}
                        >
                            <img src={logo} alt="logo" style={{ margin: '0 auto', width: '120px' }} />
                        </Toolbar>
                        <NavBar />
                    </Box>
                </Drawer>
            </>
        )
    }

    function toogleMenu() {
        if (mobile !== tablet) {
            setOpenMobile(true)
        } else {
            toggleDrawer()
        }
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} sx={{ width: "50%" }}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toogleMenu}
                            sx={{
                                marginRight: '36px',
                                ...(open && {
                                    display: {
                                        xs: 'block', lg: 'none'
                                    }
                                }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Header />
                    </Toolbar>
                </AppBar>
                <Box display={{ lg: 'flex', xs: 'none' }}>
                    <DrawerCustom variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                padding: '10% 0 18% 0',
                                px: [1],
                            }}
                        >
                            <img src={logo} alt="logo" style={{ margin: '0 auto', width: '120px' }} />
                            <IconButton onClick={toggleDrawer} sx={{ display : 'none'}}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <NavBar />
                    </DrawerCustom>
                    <MenuMobile />
                </Box>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Outlet />
                    <Box id="#scroll">
                        <ScrollTop />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function LayOut() {
    return <DashboardContent />
}