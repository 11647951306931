// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #4D9BDB; }

::-webkit-scrollbar-thumb {
  background-color: #4D9BDB;
  background-color: #4D9BDB;
  border-radius: 4.11693px; }

.MuiButton-root {
  text-transform: capitalize !important; }
`, "",{"version":3,"sources":["webpack://./src/styles/App.scss"],"names":[],"mappings":"AAAA;EACI,UAAS;EACT,WAAW,EAAA;;AAGf;EACI,iCAAiC,EAAA;;AAGrC;EACI,yBAAyB;EACzB,yBAAyB;EAEzB,wBAAwB,EAAA;;AAG5B;EACI,qCAAoC,EAAA","sourcesContent":["::-webkit-scrollbar {\n    width:5px;\n    height: 5px;\n}\n\n::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 6px #4D9BDB;\n}\n\n::-webkit-scrollbar-thumb {\n    background-color: #4D9BDB;\n    background-color: #4D9BDB;\n    // outline: 1px solid #ffffff6c;\n    border-radius: 4.11693px;\n}\n\n.MuiButton-root{\n    text-transform: capitalize!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
