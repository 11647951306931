import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IconDashboard, IconImbarcazioni, IconMaps, IconProdotti, IconQuestion, IconTutorial } from "./icons";
import List from "@mui/material/List";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";


const styleActive = {
    backgroundColor: '#0070CB',
    borderRadius: '5px',
};


export const NavBar = () => {

    const navigation = useNavigate();
    const location = useLocation();

    const IsActive = (link) => {
        if (location.pathname.indexOf(link) > 0) {
            return true;
        }
    }

    return (
        <List component="nav" sx={{ height: '100%', padding: '10px' }}>
            <Stack direction="column" justifyContent="space-between" height="100%">
                <Box>
                    <ListItemButton
                        onClick={() => {
                            navigation('/home')
                        }}
                        className={`${
                            IsActive('home') ||
                            IsActive('attivita') ||
                            IsActive('processi-previsualize') ||
                            IsActive('processi')

                        ? 'active_link ' : ' '} link_menu`}>
                        <ListItemIcon>
                            <IconDashboard color={
                                IsActive('home') ||
                                IsActive('attivita') ||
                                IsActive('processi-previsualize') ||
                                IsActive('processi')
                                    ? '#fff' : '#000'} />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" className="title_card" />
                    </ListItemButton>
                    <ListItemButton
                        className={`${
                            IsActive('mbarcazioni') || 
                            IsActive('imbacazionic-details') || 
                            IsActive('imbarcazione-previsualize')
                          ? 'active_link ' : ' '} link_menu`}
                        onClick={() => {
                            navigation('/Imbarcazioni')
                        }}>
                        <ListItemIcon>
                            <IconImbarcazioni color={
                                IsActive('mbarcazioni') ||
                                IsActive('imbacazionic-details') ||
                                IsActive('imbarcazione-previsualize')
                                  ? '#fff' : '#000'} />
                        </ListItemIcon>
                        <ListItemText primary="Imbarcazioni" />
                    </ListItemButton>
                </Box>
                {/* <Box>
                    <ListItemButton className="link_menu">
                        <ListItemIcon>
                            <IconTutorial />
                        </ListItemIcon>
                        <ListItemText primary="Tutorial" />
                    </ListItemButton>
                    <ListItemButton className="link_menu">
                        <ListItemIcon>
                            <IconQuestion />
                        </ListItemIcon>
                        <ListItemText primary="Assistenza" />
                    </ListItemButton>
                </Box> */}
            </Stack>
        </List>
    )
}