// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f2f3;
  height: 100vh;
  width: 100vw; }
`, "",{"version":3,"sources":["webpack://./src/components/pageLoader/loader.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,YAAY,EAAA","sourcesContent":[".loader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #f1f2f3;\n    height: 100vh;\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
