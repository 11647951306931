export function VerifyAuth() {

    const parseJwt = (token) => {
        try {
            return JSON.parse(window.atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        const decodedJwt = parseJwt(user.token);
        return decodedJwt.exp * 1000 >= Date.now();
    }
    return false;

}