import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import PanelNotiche from "../pages/components/Notiche/panelNotiche";
import MenuSetting from "./menus/menu-setting";
import MenuUser from "./menus/menu-user";
import MenuFlag from "./menus/menu-flag";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 4,
        top: 2,
        padding: '0 4px',
    },
}));

const Header = () => {

    const [notification, setNotification] = useState(false);

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
            }}>
                <Box>
                    <Stack direction="row" marginRight={5} spacing={{
                        lg: 2,
                        xs: 1
                    }}>
                        {/* <MenuSetting /> */}
                        <MenuFlag />
                        <IconButton color="inherit" onClick={() => {
                            setNotification(true);
                        }}>
                            <StyledBadge color="secondary" variant="dot">
                                <NotificationsIcon fontSize={'small'} />
                            </StyledBadge>
                        </IconButton>
                        <MenuUser />
                    </Stack>
                </Box>
            </Box>
            <PanelNotiche open={notification} close={() => { setNotification(false) }} />
        </>
    );
};

export default Header;
