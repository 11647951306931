import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SuccessIcon from "../../../assets/images/icons/SuccessIcon.svg"
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled} from "@mui/material";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function SuccessDialog(props) {
    const { open, handleClose, size, modalTitle , handleAction1 , handleAction2 } = props;

    return (
        <Box>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="dialog-title"
                open={open}
                maxWidth={size}
            >
                <BootstrapDialogTitle id="dialog-title" onClose={handleClose}>
                </BootstrapDialogTitle>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" , marginBottom : '5%' }}>
                    <img width={80} src={SuccessIcon} alt="icon" />
                </Box>
                <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Typography sx={{
                        fontWeight: "bold",
                        color: "#0070CB",
                        fontSize: "1rem"
                    }} gutterBottom>
                        {modalTitle}
                    </Typography>
                    <Typography textAlign="center" gutterBottom sx={{ px: 5 }}>
                        {props.children}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ my: 2, display: "flex", justifyContent: "center", gap: 6, alignItems: "center" }}>
                    <Button variant="outlined" color='primary' onClick={handleAction1}>
                        Visualizza transazione
                    </Button>
                    <Button
                        onClick={handleAction2}
                        sx={{
                            backgroundColor: "primary",
                            '&:hover': {
                                background: "primary",
                            }
                    }} variant="contained">
                        Chiudi
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}