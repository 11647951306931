import axios from "axios";
import authHeader from "../common/authHeader";

const API_URL = 'https://gestione.valpesce.it/api/notification/'

class NotificationService {
    listUsers() {
        return axios
            .get(API_URL + "all?type=user" , {
                headers : {
                    ...authHeader()
                }
            });
    }

    clearById(id) {
        return axios
            .delete(API_URL + id , {
                headers : {
                    ...authHeader()
                }
            });
    }

    clearAll() {
        return axios
            .delete(API_URL  , {
                headers : {
                    ...authHeader()
                }
            });
    }

}

export default new NotificationService();
