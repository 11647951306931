import React, {useEffect} from 'react';
import {Avatar, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import {IconCheck, IconClose} from "../../../components/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import NotificationService from "../../../services/notification-service";
import EventBus from "../../../common/eventBus";
import TimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'
TimeAgo.addLocale(it)


const timeAgo = new TimeAgo('it')
const CardNotifiche = ({width , marg ,data , load}) => {

    const navigation = useNavigate();

    const deleteN = (id) =>{
        NotificationService.clearById(id)
            .then(function ({data}) {
                load();
                EventBus.dispatch('refresh-notification')
            })
            .catch(function () {

            })
    }

    useEffect(() => {
        console.log(data.data.boats)
    }, [])


    return (
        <>
            <Grid container marginRight={marg ?? 1} marginLeft={marg ?? 1} sx={{
                position : 'relative'
            }}>
                <Grid item xs={2} sx={{
                    position : 'absolute',
                    left : '-20px',
                    zIndex : '500',
                    marginTop : '20px'
                }}>
                    <Avatar
                        sx={{
                            bgcolor: "#E6F3FF",
                            boxShadow: '0px 4px 3px #00000025'
                        }}>
                        <IconCheck/>
                    </Avatar>
                </Grid>
                <Grid
                    xs={8}
                    sx={{
                        backgroundColor: '#fff',
                        padding: "5% 12% 0 12%",
                        minWidth: width ?? '100%',
                        maxWidth : '500px',
                        height: '200px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 20px rgba(126, 130, 153, 0.15)'
                    }}>
                    <Stack spacing={1}>
                        <Typography variant="title_card"  className="title_card">
                            Nuovo accesso da
                            imbarcazione
                        </Typography>
                        <Typography variant="sub_title_card" className="sub_title_card">
                            {timeAgo.format(moment(data.created_at).toDate())}
                        </Typography>
                        <Typography>
                            Nuovo accesso di bordo per {data.data.username}
                        </Typography>
                        <Button
                            onClick={()=>{
                                navigation('/imbacazionic-details/'+data.data.boats.id);
                            }}
                            sx={{
                                width: '70%',
                                backgroundColor: '#F4F6FB',
                                color: '#5D6371',
                                textTransform : 'Capitalize',
                                '&:hover' : {
                                    color : 'white'
                                }
                            }}
                            variant="contained"
                            disableElevation
                            endIcon={<KeyboardArrowRightIcon/>}>Dettagli</Button>
                    </Stack>
                </Grid>
                <Grid item xs={2}  sx={{
                    marginLeft : '-40px',
                }}>
                    <IconButton aria-label="delete" onClick={()=>{deleteN(data.id)}}>
                        <IconClose/>
                    </IconButton>
                </Grid>
            </Grid>
        </>
    )
};

export default CardNotifiche;
