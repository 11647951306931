import {Box, Breadcrumbs, Button, Grid, Stack, Typography} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import './imbarcozioni2.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Link, useParams} from "react-router-dom";
import {IconDoc} from "../../../components/icons";
import imbarcazioniService from "../../../services/boat-service";
import blockchainService from "../../../services/bc-service";
import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const DettaglioAlberatura = () => {

    const [imbarcazionDetailgo, setImbarcazionDetailgo] = useState(null);
    const [blockchain, setBlockchain] = useState([]);
    const {id} = useParams();
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });



    useEffect(() => {
        imbarcazioniService.getById(id)
            .then(function ({data}) {
                setImbarcazionDetailgo(data.data);
                let promises = [];
                for(let i in data.data.data_blockchain) {
                    promises.push(blockchainService.getTxReceipt(data.data.data_blockchain[i].value));
                }
                Promise.all(promises).then((results) => {
                    console.log(results);
                    setBlockchain(results);
                })
            })
            .catch(function () {

            })
            .finally(function () {

            })
    }, []);


    const Header = () => {
        return (
            <>
                <Stack padding={{
                    xs : '6%',
                    lg : "2% 6%"
                }} direction={{ lg : 'row' , xs : "column"}} justifyContent="space-between" spacing={2}>
                    <Stack spacing={2}>
                        <Typography variant="title_page" lineHeight="32px" color="#8093A6" fontSize="2rem" fontFamily="Roboto"
                                    fontWeight="bold">
                            Dati registrati in blockchain
                        </Typography>
                        <Breadcrumbs aria-label="breadcrumb" separator="›" >
                            <Link  to={"/Imbarcazioni/" } style={{ textDecoration : 'none' , color : '#8093A6'}}>
                                Imbarcazioni
                            </Link>
                            <Link  to={"/imbacazionic-details/" + imbarcazionDetailgo?.id } style={{ textDecoration : 'none' , color : '#8093A6'}}>
                                Nome imbarcazione
                            </Link>
                            <Link
                                to="#"
                                style={{ textDecoration : 'none' , color : '#4D9BDB'}}
                            >
                                {imbarcazionDetailgo ? imbarcazionDetailgo?.name : ''}
                            </Link>
                        </Breadcrumbs>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <Button
                            startIcon={<LocalPrintshopOutlinedIcon color={"#fff"}/>}
                            onClick={handlePrint}
                            variant="contained" size="large"
                            sx={{ height: '50px', textTransform: "capitalize"}}>
                            Stampa PDF
                        </Button>
                    </Stack>
                </Stack>
            </>
        )
    }

    return (
        <>
          <Header/>
            <Box
                sx={{
                backgroundColor : '#fff',
                width : '88%',
                margin : '0 auto',
                marginBottom : 3,
                padding : {
                    lg : 5,
                    xs : 3
                },
                borderRadius : '5px',
                boxShadow : "0px 4px 20px rgba(126, 130, 153, 0.15)"
            }}>
                <Box ref={printRef} className={'print-style'}>
                    <Stack direction={{
                        lg : 'row' ,
                        xs : 'column-reverse'
                    }} spacing={10}>
                        <Stack spacing={5} >
                            <Typography variant='h6' color='primary' fontWeight={'bold'}>Elenco dati registrati in blockchain <KeyboardArrowDownIcon/></Typography>
                            <Stack>
                                {blockchain?.map((el)=>{
                                    return(<>
                                        <Stack direction='row' spacing={5} alignItems="center" marginTop={1}>
                                            <p><pre>
                                                {JSON.stringify(JSON.parse(el.data.receipt), null, 2)}
                                            </pre></p>
                                        </Stack>
                                    </>)
                                })}
                            </Stack>
                            <Typography variant='h6' color='primary' fontWeight="bold"> Transazioni <KeyboardArrowDownIcon/></Typography>
                            <Stack>
                                {imbarcazionDetailgo?.data_blockchain?.map((el)=>{
                                    return(<>
                                        <Stack direction='row' spacing={5} alignItems="center" marginTop={1}>
                                            <Typography variant='h6' color='#5D6371'>Tx:</Typography>
                                            <Typography variant='body' color='#8093A6' sx={{ overflow : 'hidden' ,textOverflow : 'ellipsis' , fontSizeAdjust : '0.58'}}>
                                                {el.value}
                                            </Typography>
                                        </Stack>
                                    </>)
                                })}
                            </Stack>
                        </Stack>
                        <Stack justifyContent="center" direction="row"  width={"100%"}>
                            <Box className={'qr-code-box'}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={'hello'}
                                    viewBox={`0 0 256 256`}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default DettaglioAlberatura