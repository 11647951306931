import React, { useState } from 'react'
import { DotLoader } from 'react-spinners'
import './loader.scss'

const PageLoader = () => {

    return (
        <div className="loader">
            <DotLoader color='#0070CB' loading={true} size={80} speedMultiplier={1} />
        </div>

    )
}

export default PageLoader