import * as React from 'react';
import Drawer from "@mui/material/Drawer";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {IconClose, IconOk} from "../../../components/icons";
import CardNotifiche from "./cardNotifiche";
import {useEffect, useState} from "react";
import NotificationService from "../../../services/notification-service";
import EventBus from "../../../common/eventBus";

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 4,
        top: 2,
        padding: '0 4px',
    },
}));

const PanelNotiche = ({open = true, close}) => {

    const [notifications, setNotifications] = useState([]);

    function loadNotification() {
        NotificationService.listUsers()
            .then(function ({data}) {
                setNotifications(data.data)
            })
            .catch(function () {

            })
    }

    function clearAll() {
        NotificationService.clearAll()
            .then(function ({data}) {
                setNotifications([])
                EventBus.dispatch('refresh-notification')
            })
            .catch(function () {

            })
    }

    useEffect(() => {
        loadNotification()
    }, []);

    useEffect(() => {
        EventBus.on('refresh-notification', loadNotification)
    }, []);

    return (
        <>
            <Drawer
                anchor={"right"}
                open={open}
                sx={{
                    zIndex: '2000',
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#E6F3FF'
                    }
                }}
                onClose={close}
            >

                <Box sx={{
                    width: {
                        lg: '24vw',
                        xs: '80vw'
                    }
                }}>
                    <Box sx={{backgroundColor: '#0070CB', padding: 2}}>
                        <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                            <Stack direction="row">
                                <StyledBadge color="secondary" variant="dot">
                                    <NotificationsIcon sx={{color: 'white'}}/>
                                </StyledBadge>
                                <Typography className={"title_card"} sx={{
                                    marginLeft: "15px",
                                    color: 'white',
                                    fontSize: '.8rem',
                                    fontWeight: '500',
                                }}>
                                    Notifiche
                                </Typography>
                            </Stack>
                            <IconButton aria-label="delete" sx={{marginLeft: '-35px'}} onClick={close}>
                                <IconClose/>
                            </IconButton>
                        </Stack>
                    </Box>
                    <Stack direction="row" padding={2} justifyContent={"space-between"} alignItems="center">
                        <Button variant="text"
                                sx={{
                                    textTransform: 'Capitalize',
                                    fontSize: '.8rem',
                                    color: 'gray'
                                }}>Visualizza tutte</Button>
                        <Button
                            onClick={clearAll}
                            variant="text"
                            sx={{
                                textTransform: 'Capitalize',
                                color: 'gray'
                            }}
                            startIcon={
                                <IconOk/>
                            }>Segna come lette</Button>
                    </Stack>
                    <Stack padding={2} spacing={{
                        lg: 5,
                        xs: 4
                    }}>
                        {notifications.map((el) => {
                            return (
                                <>
                                    <Box>
                                        <CardNotifiche width={"90%"} marg={2} data={el} load={loadNotification}/>
                                    </Box>
                                </>
                            )
                        })}
                    </Stack>
                </Box>

            </Drawer>
        </>
    );
};

export default PanelNotiche;
