import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#0070CB',
    },
    primary2: {
      main: '#4D9BDB',
    },
    secondary: {
      main: '#E6F3FF',
    },
    error: {
      main: red.A400,
    },
    grey: {
      main: '#5D6371',
    },
    lightBlue: {
      main: "#E6F3FF",
    }
  },
});

export default theme;
