import {
    Box,
    Button, FormControlLabel, MenuItem, OutlinedInput, Select,
    Stack, Switch, TextField,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import AppDivider from '../../components/others/appDivider';
import SuccessDialog from '../../components/dialog/successDialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, useNavigate, useParams} from "react-router-dom";
import FishService from "../../../services/fish-service";
import {useFormik} from "formik";
import imbarcazioniService from "../../../services/boat-service";
import moment from "moment";
import EventBus from "../../../common/eventBus";


export const Subtitle = ({title, styles}) => {
    return (
        <Box sx={{
            "textAlign": "center",
            color: "primary.main",
            fontSize: 20,
            my: 2,
            fontWeight: "bold", ...styles
        }}>{title}</Box>
    )
}


const ImageLabel = ({styles, s, data}) => {
    return <Box sx={styles}>
        <Stack direction="row" justifyContent="space-between">
            <ContentItem title="Date" items={[moment(data?.created_at).format("YYYY-MM-DD")]} sizeF={s}/>
            <ContentItem title="Ora" items={[moment(data?.created_at).format("HH:mm")]} sizeF={s}/>
            <ContentItem title="Luogo" items={[`${data?.latitude}’ N`, `${data?.longitude}’ E`]} sizeF={s}/>
        </Stack>
    </Box>
}


const ContentItem = ({title, items, sizeF}) => {
    return <Box>
        <Typography sx={{
            fontWeight: 500,
            color: "primary.main",
            mb: 1,
            fontSize: sizeF
        }}
                    variant="p"
                    component="p">{title}</Typography>
        {items.map((e) => <Typography variant="p" component="p" fontSize={sizeF}>{e}</Typography>)}
    </Box>
}

const ProcessiModificaForm = () => {

    const [show, setShow] = useState(false);
    const navigation = useNavigate();
    const [detail, setDetail] = useState(null);
    const {id} = useParams();
    const [boats, setBoats] = useState([]);


    useEffect(function () {
        imbarcazioniService.list()
            .then(function ({data}) {
                console.log(data.data);
                const c = data.data.find((el) => el.id === 10);
                setBoats(data.data);
            })
            .catch(function () {

            });
    }, [])

    useEffect(() => {

        if (id) {
            FishService.getById(id)
                .then(function ({data}) {
                    setDetail(data.data);
                    fish.setValues({
                        boat: data.data.boat.id,
                        specie: data.data.fish_species,
                        quantity: `${data.data?.quantity} ${data.data?.units}`,
                        commercial: data.data.name_commercial,
                        area: "37",
                        products: data.data.products,
                        captain: `${data.data?.boat.captain.detail.first_name} ${data.data?.boat.captain.detail.last_name}`
                    })

                })
                .catch(function () {

                });
        }
    }, []);


    function handleSubmit(d) {
        const s = d.quantity.split(' ');

        EventBus.dispatch('load', true);
        FishService.update(id, {...d, unit: s[1], quantity: parseInt(s[0])})
            .then(function ({data}) {
                setTimeout(function () {
                    setShow(true);
                }, 2000);
            })
            .catch(function () {

            }).finally(function () {
            setTimeout(function () {
                EventBus.dispatch('load',false);
            },2000);
            })
    }

    const fishSchema = Yup.object().shape({
        boat: Yup.number()
            .required(),
        specie: Yup.string()
            .required(),
        captain: Yup.string(),
        area: Yup.string()
            .required(),
        quantity: Yup.string()
            .required(),
        commercial: Yup.string()
            .required(),
        products: Yup.boolean()
            .required(),
    });

    const fish = useFormik({
        initialValues: {
            specie: '',
            boat: 0,
            products: false,
            area: '',
            quantity: `0 kg`,
            commercial: "",
            captain: ''
        },
        onSubmit: handleSubmit,
        validationSchema: fishSchema
    });

    const Header = () => {
        return (
            <>
                <Stack padding={"2% 6%"} spacing={{xs: 4, lg: 3}} paddingBottom={5}
                       direction={{lg: 'row', xs: 'column'}} justifyContent="space-between">
                    <Box>
                        <Typography
                            variant="title_page"
                            color="#8093A6"
                            fontSize={{
                                lg: "2rem",
                                xs: '1.5rem'
                            }}
                            fontFamily="Roboto"
                            fontWeight="bold">
                            Completa processo di pesca
                        </Typography>
                        <Link to="/processi" style={{textDecoration: 'none', color: 'gray'}}>

                            <Stack direction="row" spacing={2} alignItems="center">
                                <ArrowBackIcon sx={{color: 'black'}}/>
                                <span style={{fontSize: '1.3rem', color: '#8093A6'}}>Processi di pesca</span>
                            </Stack>
                        </Link>
                    </Box>

                    <Stack direction={'row'}>
                        <Button variant="outlined" size="large"
                                sx={{width: '100px', height: '50px', mx: 2}}
                                onClick={() => {
                                    navigation(-1)
                                }}>
                            Annulla
                        </Button>
                        <Button variant="contained" size="large"
                                onClick={fish.handleSubmit}
                                disabled={!fish.isValid}
                                sx={{width: '150px', height: '50px', mx: 2, textTransform: "capitalize"}}>
                            Salva
                        </Button>
                    </Stack>
                </Stack>
            </>
        )
    }


    return (
        <Box>
            <Header/>
            <SuccessDialog
                open={show}
                handleAction1={() => {
                    navigation('/processi-previsualize/' + id)
                }}
                handleAction2={() => {
                    navigation('/processi')
                }}
                handleClose={() => setShow(false)}
                handleShow={() => setShow(true)}
                modalTitle="Elemento salvato con successo!"
                size="xs"
            >
                <Typography variant={'body1'}>
                    ID transazione 2039482039482084209348023492038420
                </Typography>
            </SuccessDialog>

            <Box sx={{
                width: {lg: "70%", xs: '95%'},
                mx: 'auto',
                backgroundColor: "#fff",
                py: 5,
                px: "5%",
                "box-shadow": "0px 4px 20px rgba(126, 130, 153, 0.15)",
                mb: 5
            }}
            >
                <Subtitle title="Completa processo di pesca"/>
                <AppDivider/>

                <Box padding={{lg: '4%', xs: '2%'}}>
                    <Stack sx={{
                        backgroundImage: `url(${detail?.capture.data.location})`,
                        width: "100%",
                        mx: 'auto',
                        height: {lg: '320px', xs: '250px'},
                        padding: '7%',
                        borderRadius: "12px"
                    }} justifyContent={"flex-end"}>
                        <ImageLabel
                            s={"12px"}
                            data={detail}
                            styles={{
                                backgroundColor: "#ffffff6c",
                                px: 3,
                                py: 1,
                                margin: '0 auto',
                                borderRadius: "10px",
                                width: {lg: '50%', xs: '100%'},
                            }}/>
                    </Stack>
                    <ImageLabel styles={{backgroundColor: "#fff", my: 3}} data={detail}/>
                    <form onSubmit={fish.handleSubmit}>
                        <Stack spacing={5}>
                            <Stack direction={"row"} spacing={2}>
                                <Stack spacing={1} width={"100%"}>
                                    <Typography variant={"body1"}
                                                fontWeight={'bold'}
                                                sx={{color: '#4D9BDB'}}>
                                        Nome imbarcazione
                                    </Typography>
                                    <Stack spacing={2} sx={{width: 300}}>
                                        <Select
                                            name="boat"
                                            disabled={true}
                                            onChange={fish.handleChange}
                                            value={fish.values.boat}
                                            sx={{backgroundColor: '#F4F6FB'}}
                                            displayEmpty
                                            inputProps={{'aria-label': 'Without label'}}
                                        >
                                            <MenuItem value="">
                                                <span style={{color: 'gray'}}>Seleziona il comandante</span>
                                            </MenuItem>
                                            {boats.map((el) => {
                                                return (
                                                    <MenuItem key={el.id} value={el.id}>{el?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1} width={"100%"}>
                                    <Typography variant={"body1"}
                                                fontWeight={'bold'}
                                                sx={{color: '#4D9BDB'}}>
                                        Capitano
                                    </Typography>
                                    <OutlinedInput
                                        name="name"
                                        fullWidth
                                        disabled={true}
                                        onChange={fish.handleChange}
                                        value={fish.values.captain}
                                        placeholder="[Nome capitano]"/>
                                </Stack>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: '#4D9BDB'}}>
                                    Specie ittica
                                </Typography>
                                <OutlinedInput
                                    name="specie"
                                    onChange={fish.handleChange}
                                    value={fish.values.specie}
                                    placeholder="Nome specie ittica"/>
                            </Stack>
                            <Stack spacing={1} width={"20%"}>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: '#4D9BDB'}}>
                                    AREA FAO
                                </Typography>
                                <OutlinedInput
                                    name="area"
                                    onChange={fish.handleChange}
                                    value={fish.values.area}
                                    placeholder="Sì"/>
                            </Stack>
                            <Stack spacing={1} width={"20%"}>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: '#4D9BDB'}}>
                                    Quantità
                                </Typography>
                                <OutlinedInput
                                    name="quantity"
                                    onChange={fish.handleChange}
                                    value={fish.values.quantity}
                                    placeholder="0 Kg"/>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant={"body1"}
                                            fontWeight={'bold'}
                                            sx={{color: '#4D9BDB'}}>
                                    Nome commerciale
                                </Typography>
                                <OutlinedInput
                                    name="commercial"
                                    onChange={fish.handleChange}
                                    value={fish.values.commercial}
                                    placeholder="Seleziona nome commerciale"/>
                            </Stack>
                            <AppDivider/>
                            <Stack>
                                <Stack spacing={2} justifyContent={"center"} alignItems={"center"} direction={"row"}>
                                    <Typography variant={"body1"}
                                                fontWeight={'bold'}
                                                sx={{color: '#4D9BDB'}}>
                                        Prodotto
                                    </Typography>
                                    <Typography variant={"body1"}
                                                fontWeight={'bold'}
                                                color={'primary'}
                                                sx={{color: fish.values.products ? 'lightgray' : '#0070CB'}}>
                                        Privato
                                    </Typography>
                                    <FormControlLabel
                                        name="products"
                                        onChange={fish.handleChange}
                                        value={fish.values.products}
                                        control={<Switch />}/>
                                    <Typography variant={"body1"}
                                                fontWeight={'bold'}
                                                color={'primary'}
                                                sx={{color: !fish.values.products ? 'lightgray' : '#0070CB'}}
                                    >
                                        Pubblico
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} justifyContent={"center"} alignItems={"center"} direction={"row"}>
                                <Button variant="outlined" size="large"
                                        sx={{width: '100px', height: '50px', mx: 2}}
                                        onClick={() => {
                                            navigation(-1)
                                        }}>
                                    Annulla
                                </Button>
                                <Button variant="contained"
                                        size="large"
                                        type="submit"
                                        disabled={!fish.isValid}
                                        sx={{width: '150px', height: '50px', mx: 2}}>
                                    Salva
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>


        </Box>
    )
}

export default ProcessiModificaForm


